// A grid component that takes over layout duties if a device supports it 
// and then reflows to a 2 column "lookbook" after horizontal tablet sizes

import styled from 'styled-components';
// import presets from "../utils/presets";
// import {rhythm} from "../utils/typography";

const Lookbook = styled.section`

    .gatsby-image-wrapper{
        /* min-height:100%; */
        /* min-height:100vh; */
    }

    #header, #contact{
        min-height: 100vh;
    }

    @supports(display:grid){
        display:grid;

        @media screen 
        and (min-width: 1024px)
        and (orientation: landscape){
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
            .gatsby-image-wrapper{
                min-height:100vh;
            }
            align-items: start;
            /* tell the text sections to use column 2 */
            section{
                grid-column-start: 2;
                grid-column-end: 3;
            }
            /* then tell the grid to backfill column 1 with the images */
            grid-auto-flow: dense;
        }

    }
    
`
export default Lookbook