
//import libraries
import React from 'react'
import {Link, graphql} from 'gatsby'
import Img from 'gatsby-image'

// Get global styles
import '../com/app.css'

// import components
import Layout from '../com/Layout'
import Lookbook from '../com/Lookbook'
import HomeSection from '../com/HomeSection'
import Seo from '../com/Seo'
import Helmet from 'react-helmet'
import Copyright from '../com/Copyright'

export default ({data}) => {
return(
    <Layout>
        <Helmet>
            <meta name="og:image" content={data.ogimage.resize.src} />
            <meta name="twitter:image" content={data.ogimage.resize.src} />
        </Helmet>
        <Seo
            title="Bespoke Dresses &amp; Veils"
            description="Exclusive Luxury Wedding Dresses &amp; Veils, Handmade In England."
            // for og:image, see helmet tag and pick image name in page query
        />
        <h1 style={{visibility:`hidden`, display:`none`}}>Exclusive Luxury Wedding Dresses &amp; Veils, Handmade In England.</h1>
        <Lookbook>
            {/* generate content blocks from markdown */}
            {data.allMarkdownRemark.edges.map(({ node }) => (
                <React.Fragment>
                    <HomeSection id={node.frontmatter.section}
                        dangerouslySetInnerHTML={{ __html: node.html }}
                        style={{textAlign: node.frontmatter.align != 0 ? node.frontmatter.align : "left" }}
                    >
                    </HomeSection>
                    <Img 
                        alt={node.frontmatter.section+`-image`}
                        title={node.frontmatter.section+`-image`}
                        sizes={node.frontmatter.image.childImageSharp.fluid}
                        className={`${node.frontmatter.section}`+" "+`${node.frontmatter.section+`-image`}`}
                    />
                </React.Fragment>
            ))}

            {/* Contact Section */}
                {/* <HomeSection id="contact"
                    style={{ }}
                >
                    <h2>contact</h2>
                    <form>
                        Hello, I&apos;d like to chat about&nbsp;
                            <select type="text" name="about" placeholder="about">.
                                <option selected>select</option>
                                <option>my dream dress</option>
                                <option>a bespoke veil</option>
                                <option>featuring AgeetA Bridal in your publication</option>
                            </select>
                        
                    </form>

                    <form>
                        Hello, I&apos;d like to chat <label for="about">about</label>&nbsp;
                        <input type="text" name="about" placeholder="a dress / veil / featuring AgeetA Bridal"/>
                        
                    </form>
                    <p><Link to="/feature/">Feature AgeetA Bridal</Link> in your publication.</p>
                    <Copyright style={{}}/>
                </HomeSection>
                <Img 
                    alt="your-dream-bespoke-dress-and-veil-from-AgeetA-Bridal"
                    title="your-dream-bespoke-dress-and-veil-from-AgeetA-Bridal"
                    sizes={data.contactImage.fluid}
                    className="contact-image"
                /> */}
            {/* END Contact Section */}
        </Lookbook>
    </Layout>
    )
}

export const query = graphql`
query MyQuery {
    allMarkdownRemark(
        sort:{order: ASC, fields:[frontmatter___order]}
    ) {
      edges {
        node {
          html
          frontmatter{
              section
              image{
                  childImageSharp{
                      fluid(maxWidth:2560,  cropFocus:CENTER, fit:COVER, quality:70){
                          ...GatsbyImageSharpFluid
                      }
                  }
              }
              order
              align
          }
        }
      }
    }
    ogimage: imageSharp(original: {src: {regex: "/ageeta-bespoke-bridal-home/" }}) {
        resize(cropFocus: CENTER, height: 960, width: 1200) {
            src
        }
    }
    contactImage: imageSharp(original: {src: {regex: "/ageeta-dress-622/" }}) {
        fluid(maxWidth:2560,  cropFocus:CENTER, fit:COVER, quality:70){
            ...GatsbyImageSharpFluid
        }
    }
  }
  `

  // (sort: {order: DESC, fields: [frontmatter___order]})