import Typography from 'typography'

const typography = new Typography({
  baseFontSize: "20px",
  baseLineHeight: 1.6,
  scaleRatio: 4, //6.854
  headerWeight:'300',
  bodyWeight:'300',
  boldWeight:'700',
  includeNormalize:true,
  //get google fonts
  googleFonts:[
  {
    name: 'Montserrat',
    styles: [
        '300',
        '300i',
        '700',
    ],
  },
],
//set families
  headerFontFamily: [
    "Montserrat",
    "sans-serif",
  ],
  bodyFontFamily: [
    "Montserrat",
    "sans-serif",
  ],

})

const { rhythm, scale } = typography;

export { rhythm, scale, typography as default };
