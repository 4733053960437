import React from "react"
import styled from "styled-components"
import {rhythm} from "../utils/typography"

// const inset = rhythm(1);

const Copyright = styled.p`
  text-align:center;
`

export default()=>(
    <Copyright>&copy;{new Date().getFullYear()} AgeetA Bridal</Copyright>
)