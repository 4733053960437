import styled from 'styled-components';
// import presets from "../utils/presets";
import {rhythm} from "../utils/typography";

const Content= styled.section`
    /* globals */
    h2{
        text-align: center;
        /* margin: ${rhythm(2)} 0; */
    }
    padding: ${rhythm(2)} ${rhythm(1)};
    p:last-child{margin-bottom:0;}

    /* fall-backs for devices that don't support flexbox */
    @media screen and (min-width: 850px){
        width:50%;
        margin:0 auto;
    }

    /* main style with flexbox */
    @supports(display:flex){
        /* undo fall-backs */
        margin:0;
        width:100%;
        /* set mobile flex compatible styles */
        padding: ${rhythm(1)};
        /* padding-top: 25vh; */
        padding-top: ${rhythm(3)};
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 50%;
        min-height: 50vh;

        /* tablet portrait and 760 above */
        @media screen and (min-width: 760px){
            padding: ${rhythm(4)};
            min-height: 100vh;
        }

        /* tablet portrait and 1024 above */
        @media screen 
        and (min-width: 1024px)
        and (orientation: portrait){
            padding: 0 ${rhythm(6)};
        }

        /* GRID turns on here when screen is landscape*/

        /* tablet landscape and above */
        @media screen
        and (min-width: 1024px)
        and (orientation: landscape){
            padding: 0 ${rhythm(2)};

        }

        @media screen and (min-width: 1920px){
            padding: 0 ${rhythm(4)};
        }

        @media screen and (min-width: 2560px){
            padding: 0 ${rhythm(8)};
        }

        @media screen and (min-width: 3840px){
            padding: 0 ${rhythm(8)};
        }


    }



    /* add h2 styling to match the original design */
    h2:first-child{
    display: inline;
    /* width: unset; */
    background-color:white;
    /* color: red; */
        ::after{
            content: '';
            color:red;
            /* display:block; */
            background-color:black;
            /* width:100%; */
            height:0.2rem;
            position: relative;
            z-index: -5;
            top: -0.5em;
            /* float: right; */
        }
    }

`
export default Content